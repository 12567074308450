<template>
  <a-modal v-model="visible" @cancel="visible=false;" :width="620" title="绑定充值/提现银行卡" centered
    :footer="null">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" v-if="isBindCard">
      <a-form-item label="快捷使用：" v-if="entityBank">
        <a-row :gutter="16">
          <a-col :span="10" v-for="(item,index) in entityBank" :key="index">
            <div class="fast-chunk" :class="entityIndex === index && 'fast-active'" @click="fastChangeP(item, index)">
              <p>{{item.bankName}}</p>
              <p>{{item.bankAccount}}</p>
            </div>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="账号类型：" v-if="isSeller">
        <a-row>
          <a-col>
            <a-space :size="24">
              <a-select v-model="form.isPublic" style="width: 170px" placeholder="请选择账号类型">
                <a-select-option :key="0"> 对私 </a-select-option>
                <a-select-option :key="1"> 对公 </a-select-option>
              </a-select>
              <span class="font-error">* 个人卡则选择对私，公司卡选对公</span>
            </a-space>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="法人身份证：" v-if="!isSeller">
        <a-row>
          <a-col>
            <a-input v-model="form.legalIdCard" placeholder="请输入法人身份证号码" />
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="银行账号：">
        <a-row>
          <a-col>
            <a-input v-model="form.bankAccount" @change="accountChange"
              @input="form.bankAccount=$event.target.value.replace(/[^\d]/g,'')" @blur="blur(form.bankAccount)"
              placeholder="请输入银行账号" />
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="开户行名称：">
        <a-row>
          <a-col>
            <a-select showSearch style="width: 100%" placeholder="请输入银行关键字" v-model="form.eiconBankBranchId"
              :filter-option="filterOption" @search="searchList"
              @change="form.eiconBankBranchId = $event;form.bankName = paBankList.find(item => item.eiconBankBranchId === $event).bankNameSec">
              <a-select-option v-for="item in paBankList" :key="item.eiconBankBranchId"> {{ item.bankNameSec }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="超级网银行号：">
        <a-row>
          <a-col>
            <a-input :value="form.eiconBankBranchId" disabled placeholder="查询后自动输入" />
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="预留手机号">
        <a-row>
          <a-col>
            <a-input v-model="form.phone" :maxLength="11" @input="form.phone=$event.target.value.replace(/[^\d]/g,'')"
              placeholder="请输入预留手机号" />
          </a-col>
        </a-row>
      </a-form-item>

      <div class="foot">
        <a-button type="primary" size="large" @click="bankAuth" :loading="authLoading">银行鉴权</a-button>
      </div>

    </a-form>
    <a-row v-else>
      <a-col :span="24" align="center">
        <span class="authentication money icon-m"></span>
      </a-col>
      <a-col :span="24" align='center'> <span class="title">{{!bandSuccess ? !isPublic ? '银联鉴权' : '小额鉴权' : '绑卡成功'}}</span> </a-col>
      <template v-if="!bandSuccess">
        <a-col :span="24" class="text-space col-size" style="text-align: center;padding: 0 64px;">
          {{ !isPublic ? '已向预留手机号码发送验证短信，请在以下核对框内输入短信验证码，完成最终鉴权！' : `已向您申请绑定的账号中转入小于等于0.5元的随机金额，并短信通知，请在以下金额核对框内输入具体金额，完成最终鉴权！`}}</a-col>
        <a-col :span="24" class="text-space-max" align="center" style="padding-left: 32px;" v-if="isPublic">收到金额：
          <a-input-number :precision="2" style="width:160px" v-model="formAAA.amount" placeholder="请输入"/> 元
        </a-col>
        <a-col :span="24" class="text-space" align="center">{{!isPublic ? '验证码：' : '鉴权序列号：'}}
          <a-input style="width:160px" v-model="formAAA.code" placeholder="请输入" />
        </a-col>
        <a-col :span="24" class="text-space" v-if="isPublic">
          <a-alert show-icon style="font-size:12px" :message="`若不确定金额，可关闭此页面，事后前往${isSeller ? '卖家' : '买家'}中心-财务管理进行鉴权。`" type="info" closable />
        </a-col>
        <a-col class="text-space-max" :span="24" align="center">
          <a-space :size="24">
            <a-button class="btn" @click="visible = false">取消</a-button>
            <a-button type="primary" class="btn primary" :loading="loading" @click="auth">确认</a-button>
          </a-space>
        </a-col>
      </template>
      <template v-else>
        <a-col class="text-space-max-suc" :span="24" align="center">
          <a-button type="primary" class="btn primary" @click="visible = false">确认</a-button>
        </a-col>
      </template>
      
    </a-row>
  </a-modal>
</template>

<script>
import { banksList, supplierBind, purchaserBind } from '@/api/pa'
import { supplierAuth, purchaserAuth } from '@/api/pa'
import bank from '@/utils/banks'
export default {
  props: {
    isSeller: {
      type: Boolean,
      default: false
    },
    entityBank: {
      type: Array,
      default: () => undefined
    },
    enableContinueBindCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      entityIndex: undefined,
      authLoading: false,
      isBindCard: true,
      bandSuccess: false,
      Bank: [], // 平安银行列表
      paBankList: [], // 平安银行列表
      form: {
        isPublic: 0, // 是否是公户(0 私户，1公户)
        bankName: undefined, // 银行名称
        bankAccount: undefined, // 银行账号
        eiconBankBranchId: undefined, // 超级网银号
        phone: undefined, // 手机号
        supplierId: this.$route.query.entityId,
        purchaserId: this.$route.query.entityId,
        purchaserBankAccountId: undefined, // 快捷选定的采购商银行卡id
        legalIdCard: undefined, // 法人身份证号码
      },
      loading: false,
      formAAA: {
        purchaserId: this.$route.query.entityId,
        supplierId: this.$route.query.entityId,
        code: undefined,
        amount: undefined
      }
    }
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.bandSuccess = false
        this.entityIndex = undefined
        this.authLoading = false
        this.loading = false
        this.form = {
          isPublic: 0, // 是否是公户(0 私户，1公户)
          bankName: undefined, // 银行名称
          bankAccount: undefined, // 银行账号
          eiconBankBranchId: undefined, // 超级网银号
          phone: undefined, // 手机号
          supplierId: this.$route.query.entityId,
          purchaserId: this.$route.query.entityId,
          purchaserBankAccountId: undefined, // 快捷选定的采购商银行卡id
          legalIdCard: undefined, // 法人身份证号码
        }
        this.formAAA = {
          purchaserId: this.$route.query.entityId,
          supplierId: this.$route.query.entityId,
          code: undefined,
          amount: undefined
        }
      }
    }
  },
  created() {
    // 获取所有开户行
    banksList().then(res => {
      if (res.code === 200) {
        this.Bank = res.data
      } else {
        this.$message.error(res.message)
      }
    })
  },
  computed: {
    isPublic() {
      return this.isSeller ? this.form.isPublic : 1 
    }
  },
  methods: {
    // 重置数据
    reset() {
      this.entityIndex = undefined
        this.authLoading = false
        this.form = {
          isPublic: 0, // 是否是公户(0 私户，1公户)
          bankName: undefined, // 银行名称
          bankAccount: undefined, // 银行账号
          eiconBankBranchId: undefined, // 超级网银号
          phone: undefined, // 手机号
          supplierId: this.$route.query.entityId,
          purchaserId: this.$route.query.entityId,
          purchaserBankAccountId: undefined, // 快捷选定的采购商银行卡id
          legalIdCard: undefined, // 法人身份证号码
        }
    },
    // 提现绑卡
    toBind () {
      this.visible = true
      this.isBindCard = true
    },
    // 继续鉴权
    toAAA (e) {
      this.visible = true
      this.isBindCard = false
      this.form.isPublic = e ? 1 : 0
    },
    // 搜索开户行
    searchList(search) {
      if (!search) return
      banksList({ search }).then(res => {
        if (res.code === 200) {
          this.paBankList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 失焦获取银行
    blur(e) {
      const bankName = bank(e).bankName
      console.log(bankName);
      if (bankName) {
        // this.Bank.forEach((item) => {
        //   if (item.bankNameSec === bankName) {
        //     this.form.eiconBankBranchId = item.eiconBankBranchId
        //     this.form.bankName = bankName
        //     this.paBankList = [item]
        //   }
        // })
        for (let index = 0; index < this.Bank.length; index++) {
          const item = this.Bank[index];
          if (item.bankNameSec === bankName) {
            this.form.eiconBankBranchId = item.eiconBankBranchId
            this.form.bankName = bankName
            this.paBankList = [item]
            return
          } else {
            this.form.eiconBankBranchId = undefined
            this.form.bankName = undefined
            this.paBankList = []
          }
        }
      } else {
        this.form.bankName = undefined
        this.form.eiconBankBranchId = undefined
        this.paBankList = []
      }
    },
    // 筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 买家选择
    fastChangeP(i, index) {
      if (this.entityIndex === index) {
        this.entityIndex = undefined
        this.form.purchaserBankAccountId = undefined
        return
      }
      this.entityIndex = index
      this.form.purchaserBankAccountId = i.id
      this.form.bankAccount = i.bankAccount
      this.blur(this.form.bankAccount)
    },
    // 开户行号改动时 移除快捷使用的样式
    accountChange() {
      this.entityIndex = undefined
      this.form.purchaserBankAccountId = undefined
    },
    // 银行鉴权
    bankAuth() {
      console.log(this.form);
      if (!this.isSeller && !this.form.legalIdCard) {
        this.$message.error('请填写法人身份证号码')
        return
      } else if (!this.form.bankName) {
        this.$message.error('请选择开户行')
        return
      } else if (!this.form.bankAccount) {
        this.$message.error('请填写银行卡账号')
        return
      } else if (!this.form.eiconBankBranchId) {
        this.$message.error('请选择开户行')
        return
      } else if (!this.form.phone) {
        this.$message.error('请填写预留手机号')
        return
      }
      this.authLoading = true
      if (this.isSeller) {
        supplierBind(this.form).then(res => {
          this.authLoading = false
          if (res.code === 200) {
            console.log(res);
            this.$message.success(res.message)
            this.isBindCard= false
            this.$emit('ok')
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        purchaserBind(this.form).then(res => {
          this.authLoading = false
          if (res.code === 200) {
            console.log(res);
            this.$message.success(res.message)
            this.isBindCard = false
            this.$emit('ok')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    auth() {
      if (!this.formAAA.amount && this.isPublic) {
        this.$message.error('请输入收到的金额')
        return
      } else if (!this.formAAA.code) {
        this.$message.error('请输入鉴权序列号')
        return
      } 
      this.loading = true
      if (this.isSeller) {
        supplierAuth(this.formAAA).then(res => {
          this.loading = false
          if (res.code === 200) {
            console.log(res);
            this.bandSuccess = true
            this.$emit('ok')
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        purchaserAuth(this.formAAA).then(res => {
          this.loading = false
          if (res.code === 200) {
            console.log(res);
            this.bandSuccess = true
            this.$emit('ok')
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.foot {
  margin-top: 24px;
  text-align: center;
  button {
    width: 150px;
    border-radius: 5px;
  }
}
.fast-chunk {
  padding: 8px;
  background-color: rgba(242, 242, 242, 1);
  border-radius: 5px;
  font-size: 12px !important;
  margin-bottom: 8px;
  color: #d7d7d7;
  cursor: pointer;
  p {
    line-height: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700;
  }
}
.fast-active {
  color: #fff;
  background: rgba(91, 176, 255, 1);
}
.font-error {
  color: @error;
}
/deep/ .ant-form-item {
  margin-bottom: 16px;
}
.icon-m {
  color: rgb(109, 168, 86);
  font-weight: 700;
  font-size: 60px;
}
.title {
  font-size: 24px;
  color: #000;
  font-weight: 650;
}
.text-space {
  margin-top: 20px;
}
.text-space-max {
  margin-top: 30px;
}
.text-space-max-suc {
  margin: 32px 0;
}
.btn {
  font-size: 18px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
}
.primary {
  background-color: rgb(109, 186, 68);
}
/deep/ .ant-col {
    margin-bottom: 0;
}
.col-size {
  font-size: 15px;
}
</style>