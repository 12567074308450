<template>
  <!-- 开通余额支付 -->
  <a-modal
    title="余额支付开通成功"
    :visible="isPay"
    @ok="handleOk"
    @cancel="isPay = false"
    okText="立即绑卡"
    cancelText="关闭窗口"
    class="modal"
    centered
  >
    <div class="success-message">
      <img src="../../assets/images/light.png" alt="">
      <div class="msg-text">开通成功</div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data(){
    return {
      isPay: false,//是否显示
    }
  },
  
  methods:{
    handleOk(){
      this.isPay = false
      this.$emit('ok')
    }
  }
};
</script>

<style lang="less" scoped>
  .success-message{
    text-align: center;
    padding: 20px 0;
    .msg-text{
      font-size: 24px;
      font-weight: bold;
    }
  }
  .content{
      border-width: 0px;
      left: 0px;
      top: 0px;
      width: 460px;
      height: 150px;
      background: inherit;
      background-color: rgba(242, 242, 242, 1);
      border-color: rgba(215, 215, 215, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      box-shadow: none;
      text-align: left;
      line-height: 28px;
      padding:20px;
  }
  .modal /deep/ .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding: 16px 0 46px;
  }
</style>