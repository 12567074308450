<template>
  <!-- 设置支付密码 -->
  <a-modal v-model="visible" title="设置支付密码" centered :footer="null" class="edit-pwd">
    <p class="l-flex-allcenter">当前管理员手机号码：{{phone}}</p>
    <p class="l-flex-allcenter">
      <uInput v-model="setForm.code" :hasClear="false" max="6" placeholder="短信验证码">
        <span :class="form.switchCode && 'verify-code'"
          @click="sendMsg">{{form.count === 60 ? '发送验证码' : `${form.count}秒后重发`}}</span>
        <template #icon>
          <a-icon type="message" />
        </template>
      </uInput>
    </p>
    <p class="l-flex-allcenter">
      <uInput type="password" max="6" :sign="true" v-model="pwd" placeholder="设置设置6位数字支付密码" @clear="pwd = $event">
        <template #icon>
          <a-icon type="lock" />
        </template>
      </uInput>
    </p>
    <p class="l-flex-allcenter">
      <uInput type="password" max="6" :sign="true" v-model="setForm.password" placeholder="确认新密码"
        @clear="setForm.password = $event">
        <template #icon>
          <a-icon type="lock" />
        </template>
      </uInput>
    </p>

    <p class="l-flex-allcenter">
      <a-button type="primary" @click="enter">确认设置</a-button>
    </p>

  </a-modal>
</template>

<script>
import uInput from '@/components/uInput'
import { getEntityPhone, sendEntityCode, setEntityPwd } from '@/api/pa'
export default {
  components: {
    uInput,
  },
  props: {
    isSeller: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      form: {
        switchCode: false,
        count: 60
      },
      phone: '',
      pwd: '',
      setForm: {
        entityId: this.$route.query.entityId, type: this.isSeller ? 'S' : 'P', code: undefined, password: undefined
      }
    }
  },
  mounted() {
    getEntityPhone({ entityId: this.$route.query.entityId, type: this.isSeller ? 'S' : 'P' }).then(res => {
      if (res.code === 200) {
        this.phone = res.data
      }
    })
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.reset()
      }
    }
  },
  methods: {
    // 获取验证码
    sendMsg() {
      if (!this.form.switchCode) {
        sendEntityCode({ entityId: this.$route.query.entityId, type: this.isSeller ? 'S' : 'P' }).then(res => {
          if (res.code === 200) {
            this.form.switchCode = true
            this.countDown()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    countDown() {
      if (this.form.count > 0 && this.form.switchCode) {
        this.form.count--
        setTimeout(this.countDown, 1000)
      } else {
        this.form.switchCode = false
        this.form.count = 60
      }
    },
    enter() {
      if (!this.setForm.code) {
        this.$message.error('请输入验证码')
        return
      }
      if (!this.setForm.password || !this.pwd) {
        this.$message.error('请输入密码')
        return
      }
      if (this.setForm.password.length !== 6 || this.pwd.length !== 6) {
        this.$message.error('请输入正确位数的密码')
        return
      }
      if (this.setForm.password !== this.pwd) {
        this.$message.error('两次输入的密码不同')
        return
      }
      setEntityPwd(this.setForm).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.reset()
          this.$emit('success')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    reset() {
      this.visible = false
      this.text = 'text'
      this.form = {
        switchCode: false,
        count: 60
      }
      this.pwd = ''
      this.setForm = {
        entityId: this.$route.query.entityId, type: this.isSeller ? 'S' : 'P', code: undefined, password: undefined
      }
    }
  }
}
</script>

<style lang="less" scoped>
.edit-pwd {
  .l-flex-allcenter {
    margin-bottom: 20px;
    span {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: @primary;
    }
    .verify-code {
      color: #cccccc;
    }
    &:nth-child(1) {
      font-size: 16px;
    }
  }
}
</style>