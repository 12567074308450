import request from '@/utils/request'

// 搜索平安银行列表
export function banksList(params) {
    return request({
        url: '/entity/bp/supper/bank/list',
        method: 'get',
        params
    })
}

// 账户开通情况
export function paInfo(params) {
    return request({
        url: '/entity/bp/info/get',
        method: 'get',
        params
    })
}

// 开通子账户-采购商
export function openSubAccountPurchaser(data) {
    return request({
        url: '/entity/bp/purchaser/sub/account/open',
        method: 'POST',
        data
    })
}

// 开通子账户-供应商
export function openSubAccountSupplier(data) {
    return request({
        url: '/entity/bp/supplier/sub/account/open',
        method: 'POST',
        data
    })
}

// 采购商-绑卡
export function purchaserBind(data) {
    const {bankAccount,bankName,eiconBankBranchId,phone,purchaserId,legalIdCard,purchaserBankAccountId} = data
    return request({
        url: '/entity/bp/purchaser/bind/bank/card',
        method: 'POST',
        data: {bankAccount,bankName,eiconBankBranchId,phone,purchaserId,legalIdCard,purchaserBankAccountId}
    })
}

// 供应商-绑卡
export function supplierBind(data) {
    const {bankAccount,bankName,eiconBankBranchId,phone,supplierId,isPublic} = data
    return request({
        url: '/entity/bp/supplier/bind/bank/card',
        method: 'POST',
        data: {bankAccount,bankName,eiconBankBranchId,phone,supplierId,isPublic}
    })
}

// 采购商-绑卡-确认鉴权
export function purchaserAuth(data) {
    const {purchaserId, code, amount} = data
    return request({
        url: '/entity/bp/purchaser/bind/bank/card/auth',
        method: 'POST',
        data: {purchaserId, code, amount}
    })
}

// 供应商-绑卡-确认鉴权
export function supplierAuth(data) {
    const {supplierId, code, amount} = data
    return request({
        url: '/entity/bp/supplier/bind/bank/card/auth',
        method: 'POST',
        data: {supplierId, code, amount}
    })
}

// 供应商-解绑
export function supplierUnbind(data) {
    return request({
        url: '/entity/bp/supplier/remove/bank/card',
        method: 'POST',
        data
    })
}

// 采购商-解绑
export function purchaserUnbind(data) {
    return request({
        url: '/entity/bp/purchaser/remove/bank/card',
        method: 'POST',
        data
    })
}

// 获取充值密码的手机号
export function getEntityPhone(params) {
    return request({
        url: '/entity/bp/password/reset/phone',
        method: 'get',
        params
    })
}

// 发送短信验证码
export function sendEntityCode(data) {
    return request({
        url: '/entity/bp/password/reset/msg/send',
        method: 'POST',
        data
    })
}

// 设置密码
export function setEntityPwd(data) {
    return request({
        url: '/entity/bp/password/reset',
        method: 'POST',
        data
    })
}

// 查询订单是否支持平安付
export function enablePa(params) {
    return request({
        url: '/entity/bp/order/enable',
        method: 'get',
        params
    })
}

// 平安支付订单
export function payPa(data) {
    return request({
        url: '/entity/bp/order/pay',
        method: 'POST',
        data
    })
}

// 查询卖家可提现订单列表
export function withdrawList(params) {
    const {pageNum, pageSize, supplierId} = params
    return request({
        url: '/entity/bp/order/withdraw/list',
        method: 'get',
        params: {pageNum, pageSize, supplierId}
    })
}

// 供应商提现
export function withdraw(data) {
    return request({
        url: '/entity/bp/order/withdraw',
        method: 'post',
        data
    })
}

// 供应商-账户交易明细
export function suppliertradeList(params) {
    const {pageNum, supplierId, startTime, endTime, pageSize, search} = params
    return request({
        url: '/entity/bp/supplier/trade/detail/list',
        method: 'get',
        params: {pageNum, supplierId, startTime, endTime, pageSize, search}
    })
}

// 采购商-账户交易明细
export function purchaserTradeList(params) {
    const {pageNum, purchaserId, startTime, endTime, pageSize, search} = params
    return request({
        url: '/entity/bp/purchaser/trade/detail/list',
        method: 'get',
        params: {pageNum, purchaserId, startTime, endTime, pageSize, search} 
    })
}

// 供应商-账户提现明细
export function supplierWithdrawList(params) {
    const {pageNum, supplierId, timeType, endDate, startDate} = params
    return request({
        url: '/entity/bp/supplier/withdraw/list',
        method: 'get',
        params: {pageNum, supplierId, timeType, endDate, startDate} 
    })
}

// 供应商提现申请记录
export function supplierWithdrawApplyList(params) {
    const {pageNum, pageSize, supplierId, endTime, startTime, type, search} = params
    return request({
        url: '/entity/bp/supplier/withdraw/apply/list',
        method: 'get',
        params: {pageNum, pageSize, supplierId, endTime, startTime, type, search}
    })
}